import React, { useState } from 'react';
import styles from './guest-area.module.css';
import AnimatedCursor from '../../components/animated-cursor/animated-cursor';

const GuestArea = ({ onAuthenticate }) => {
    const [password, setPassword] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
      onAuthenticate(password); // Pass the entered password to the parent component
    };
  

  return (
    <div className={styles.guestArea}>
        <AnimatedCursor
            innerSize={5}
            outerSize={40}
            color='0, 0, 0'
            outerAlpha={0.2}
            innerScale={0.5}
            outerScale={1.5}
        />
      <div className={styles.modal}>
        <h2>Guest Area</h2>
        <p>Please enter the password below.</p>
        <a href="mailto:yaldasafarli@hotmail.com" className={styles.requestAccess}>
          Request access here.
        </a>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className={styles.passwordInput}
          />
          <button type="submit" className={styles.submitButton}>
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default GuestArea;
