import React from 'react'

import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import ob_image from '../../assets/ob_home_image_mobile.png';
import ob_image_mobile from '../../assets/ob-image-mobile.png';
import ob_persona_1 from '../../assets/ob-persona-1.jpg';
import ob_persona_2 from '../../assets/ob-persona-2.jpg';
import ob_ia from '../../assets/ob-IA.png';


import ob_sketch from '../../assets/ob-sketch.jpg';

import ob_wirefram_1 from '../../assets/ob-wireframe-1.jpg';
import ob_wirefram_2 from '../../assets/ob-wireframe-2.jpg';
import ob_wirefram_3 from '../../assets/ob-wireframe-3.png';
import ob_wirefram_4 from '../../assets/ob-wireframe-4.jpg';
import ob_wirefram_5 from '../../assets/ob-wireframe-5.jpg';
import ob_wirefram_6 from '../../assets/ob-wireframe-6.jpg';
import ob_wirefram_7 from '../../assets/ob-wireframe-7.jpg';
import ob_wirefram_8 from '../../assets/ob-wireframe-8.jpg';


import ob_ui_1 from '../../assets/ob-ui-1.jpg';
import ob_ui_2 from '../../assets/ob-ui-2.jpg';
import ob_ui_3 from '../../assets/ob-ui-3.png';
import ob_ui_4 from '../../assets/ob-ui-4.png';
import ob_ui_5 from '../../assets/ob-ui-5.png';
import ob_ui_6 from '../../assets/ob-ui-6.jpg';
import ob_ui_7 from '../../assets/ob-ui-7.png';
import ob_ui_8 from '../../assets/ob-ui-8.png';

import './projects.css';
import useIsMobile from '../../hooks/useIsMobile';

import AnimatedCursor from '../../components/animated-cursor/animated-cursor'

import { CircularButton, NextProjectSection, ProjectTitleSection, SectionTitle, SubsectionTitle } from '../../components/shared-proj-components';

const prototype_link = "https://www.figma.com/proto/3NdcWqxt2ARIVMbL5coZOL/Oral-Brooks-Website?page-id=0%3A1&node-id=477%3A32&viewport=241%2C48%2C0.12&scaling=scale-down&starting-point-node-id=477%3A2"

function OralBrooksProjectPage() {
  const { isMobile, width } = useIsMobile();

  return (
    <div style={{ backgroundColor: '#FFDD85' }} className="app">
      <AnimatedCursor
        innerSize={5}
        outerSize={40}
        color='25, 57, 53'
        outerAlpha={0.2}
        innerScale={0.5}
        outerScale={1.5}
      />
      <Header/>
      <ProjectTitleSection 
        color={'#623D2E'}
        subtitleColor={'#193935'}
        title={'Oral Brooks'} 
        subtitle={'WEBSITE DESIGN'} 
        date={'AUG - OCT 2021'} 
        description={'Designing a website that incorporates 3 different services provided by Oral Brooks.'} 
        image={isMobile ? ob_image_mobile : ob_image}
        descriptionWidth={380}
      />
      <SectionTitle title='Design Process' color='#623D2E' />

      <div style={{...style.stepContainer, ...(isMobile && mobileStyle.stepContainer)}}>
        {!isMobile && <div style={{ display: 'flex', flex: 2 }}>
          <CircularButton 
            split 
            borderColor='#000'
            textColor='#fff'
            backgroundColor='#000'
            link={prototype_link} 
            text="View Prototype" />
        </div>}
        
        <div style={{ display: 'flex', flexWrap: 'wrap', flex: 4}}>
          <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, flexDirection: 'row', ...(isMobile && {justifyContent: 'center'}) }}>
            <div style={{ color: '#193935', fontWeight: 600 }} className="design-process-step design-process-number">1.</div>
            <div>
              <div style={style.designProcessStep}>Research</div>
              <div style={style.designProcessSubstep}>User Interview</div>
              <div style={style.designProcessSubstep}>Personas</div>
            </div>
          </div>
          <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, flexDirection: 'row' }}>
            <div style={{ color: '#193935', fontWeight: 600 }} className="design-process-step design-process-number">2.</div>
            <div>
              <div style={style.designProcessStep}>Information Architecture</div>
              <div style={style.designProcessSubstep}>IA</div>
              <div style={style.designProcessSubstep}>Sketches</div>
            </div>
          </div>
          <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, flexDirection: 'row', ...(isMobile && {justifyContent: 'center'}) }}>
            <div style={{ color: '#193935', fontWeight: 600 }} className="design-process-step design-process-number">3.</div>
            <div>
              <div style={style.designProcessStep}>Wireframing</div>
              <div style={style.designProcessSubstep}>Wireframes</div>
            </div>
          </div>
          <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, flexDirection: 'row' }}>
            <div style={{ color: '#193935', fontWeight: 600 }} className="design-process-step design-process-number">4.</div>
            <div>
              <div style={style.designProcessStep}>UI Design</div>
              <div style={style.designProcessSubstep}>Style Guide</div>
              <div style={style.designProcessSubstep}>UI Design</div>
            </div>
          </div>
          {!!isMobile && <div style={{ width: '100%', marginTop: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 40}}>
            <CircularButton 
              split 
              borderColor='#000'
              textColor='#fff'
              backgroundColor='#000'
              link={prototype_link} 
              text="View Prototype" />
          </div>}
        </div>
      </div>
      
      <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
        {/* <div style={{ display: 'flex', flex: 2, ...( isMobile && {alignItems: 'center', marginBottom: 40}) }}> 
            <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}>Introduction</div>
            {!!isMobile && <div style={{...style.sectionLine, ...(isMobile && mobileStyle.sectionLine)}} />}
        </div> */}
        <SubsectionTitle title='Introduction' color='#623D2E' lineColor='#623D2E' />

        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
          <span>The challenge for this project was designing a website that incporporates 3 different services provided by Oral Brooks. The services are:</span>
          <br/>
          <ol>
                <li style={{ marginBottom: 8 }}><b>Voiceover:</b> A portfolio of his commercial and corporate voice demos</li>
                <li style={{ marginBottom: 8 }}><b>Vows Inked:</b> Works with clients to write custom vows and wedding toasts for their wedding day</li>
                <li style={{ marginBottom: 8 }}><b>Love Distillery:</b> A spoken word album which is a cinematic tail about love, life, and loss</li>
          </ol>
          <br/>
          <span>
            My goal for this project was to create a connection between the three businesses that will allow for a seamless navigation from one business to another. One way I could achieve that is by designing a simple, engaging, and concise website that will leave no room for confusion.
          </span>
          <span><br/>
            My role for this project was to work on the user discovery and come up with solutions for potential usability problems. My partner, Tina, worked on the wireframes and the UI Design. 
          </span>
        </div>
      </div>
     
      <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
        <SubsectionTitle title='Research' color='#623D2E' lineColor='#623D2E' />        
        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
        {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 8 }}>User Interviews</div>}
          <span>The 4 users we interviewed were between the ages of 25-50 who all had full-time jobs with an interest in voice over, custom vows, and poetry. My objective for these interviews were to:</span>
          
          <ul style={{ marginBottom: 48 }}>
                <li style={{ marginBottom: 8 }}>Identify their goals when it came to the different services </li>
                <li style={{ marginBottom: 8 }}>Understand pain points or reservations when it came to hiring/collaborating with artists like Oral Brooks</li>
          </ul>
          {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 40 }}>Personas</div>}
          <img src={ob_persona_1} style={{width: '100%', marginBottom: 40}} alt="persona 1 Johnny" />
          <img src={ob_persona_2} style={{width: '100%'}} alt="persona 2 Sally" />
        </div>
      </div>
      
      <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
        <SubsectionTitle title='Information Architecture' color='#623D2E' lineColor='#623D2E' />        

        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
          {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 8 }}>IA</div>}
          <span>The information architecture for the website is broad and shallow, which makes it easier for the users to identify the three services and make a choice on which service to learn more about. </span>
          
          <img src={ob_ia} style={{width: '100%', marginBottom: 40}} alt="oral brooks" />
          {!isMobile && <div style={{ ...style.subheadings, ...style.bold }}>Sketch</div>}
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 12, ...(!isMobile && {marginRight: -100}) }}>
            <div style={{ flex: 1, marginRight: 24}}>
              <img src={ob_sketch} style={{width: isMobile ? '100%' : '60%'}} alt="oral brooks" />
            </div>
          </div>
          
        </div>
      </div>
       
      <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
        <SubsectionTitle title='Wireframing' color='#623D2E' lineColor='#623D2E' />        

        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
          {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 8 }}>Wireframing</div>}
          <span>All 3 services are connected through the concept of “the spoken word”, which allowed for a layout that aligned with all the service pages in a seamless and consistent manner.</span>
          <div style={{ marginTop: 40, marginBottom: 12, display: 'flex', flex: 1, flexDirection: 'row', marginRight: -100, ...(isMobile && {flexWrap: 'wrap', marginRight: -32 })}}>
            <img src={ob_wirefram_1} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
            <img src={ob_wirefram_2} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
            <img src={ob_wirefram_3} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
            <img src={ob_wirefram_4} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
          </div>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginRight: -100, ...(isMobile && {flexWrap: 'wrap', marginRight: -32})}}>
            <img src={ob_wirefram_5} style={{marginRight: 8, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
            <img src={ob_wirefram_6} style={{marginRight: 8, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
            <img src={ob_wirefram_7} style={{marginRight: 8, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
            <img src={ob_wirefram_8} style={{marginRight: 8, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
          </div>
        </div>
      </div>
     
      <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
        <div style={{ display: 'flex', flex: width < 1400 ? 1 : 2, ...( isMobile ? {alignItems: 'center', marginBottom: 40 } : { flexDirection: 'column', justifyContent: 'space-between'}) }}> 
            <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}>UI Design</div>
            {!!isMobile && <div style={{...style.sectionLine, ...(isMobile && mobileStyle.sectionLine)}} />}
            {!(width < 1400) &&
              <CircularButton 
              split 
              borderColor='#000'
              textColor='#fff'
              backgroundColor='#000'
              link={prototype_link} 
              text="View Prototype" />}
        </div>
            
        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
          {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 32 }}>Style Guide</div>}
          {!isMobile && <div style={{ display: 'flex', flexDirection: 'row'}}>
            <div style={{ flex:2, flexDirection: 'column'}}>
              <span>TYPOGRAPHY</span>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 32}}>
                <div style={{ width: 115, display: 'flex', flexDirection: 'column'}}>
                  <span style={{color: '#282828', marginBottom: 50, fontSize: 18}}>TITLE</span>
                  <span style={{color: '#282828', marginBottom: 50}}>BODY</span>
                  <span style={{color: '#282828', marginBottom: 50}}>LABELS</span>
                  <span style={{color: '#282828', marginBottom: 50}}>BUTTONS</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 24}}>
                  <span style={{fontSize: width < 1200 ? 12 : 24, color: '#535353', marginBottom: 50, fontWeight: 500, fontFamily: 'CarlMarx'}}>CARLMARX, REGULAR, 136 PX</span>
                  <span style={{fontSize: width < 1200 ? 12 : 24, color: '#535353', marginBottom: 50, fontFamily: 'CarlMarx' }}>CARLMARX, REGULAR, 62 PX</span>
                  <span style={{fontSize: width < 1200 ? 12 : 18, color: '#535353', marginBottom: 50, fontFamily: 'Roboto' }}>ROBOTO, REGULAR, 18 PX</span>
                  <span style={{fontSize: width < 1200 ? 12 : 24, color: '#535353', marginBottom: 50, fontFamily: 'CarlMarx' }}>CARLMARX, REGULAR, 24 PX</span>
                </div>
              </div>
            </div>
            <div style={{ flex: 1, flexDirection: 'column', paddingLeft: 70}}>
              <span>COLOUR PALETTE</span>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 32 }}>
                <div style={{ flex: 1 }}>
                  <div style={{ marginBottom: 36, marginRight: 40 }}>
                    <div style={{backgroundColor: '#2B5A54', borderRadius: '50%', height: 69, width: 69, marginBottom: 8}}/>
                    <div style={{ fontSize: 14, color: '#000' }}>PRIMARY</div>
                    <div style={{ fontSize: 14, color: '#000' }}>#2B5A54</div>
                  </div>
                  <div>
                    <div style={{backgroundColor: '#B59250', borderRadius: '50%', height: 69, width: 69, marginBottom: 8}}/>
                    <div style={{ fontSize: 14, color: '#000' }}>NEUTRAL 1</div>
                    <div style={{ fontSize: 14, color: '#000' }}>#B59250</div>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ marginBottom: 36, marginRight: 40 }}>
                    <div style={{backgroundColor: '#E0BE74', borderRadius: '50%', height: 69, width: 69, marginBottom: 8}}/>
                    <div style={{ fontSize: 14, color: '#000' }}>SECONDARY</div>
                    <div style={{ fontSize: 14, color: '#000' }}>#E0BE74</div>
                  </div>    
                  <div style={{ marginBottom: 36 }}>
                    <div style={{backgroundColor: '#F0EFE6', borderRadius: '50%', height: 69, width: 69, marginBottom: 8}}/>
                    <div style={{ fontSize: 14, color: '#000' }}>NEUTRAL 2</div>
                    <div style={{ fontSize: 14, color: '#000' }}>#F0EFE6</div>
                  </div>                
                </div>
                <div style={{ flex: 2 }}>
                </div>
              </div>
            </div>
          </div>}
          {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 24 }}>UI Design</div>}
          <div style={{ marginTop: 40, marginBottom: 12, display: 'flex', flex: 1, flexDirection: 'row', marginRight: -100, ...(isMobile && {flexWrap: 'wrap', marginRight: -32 })}}>
            <img src={ob_ui_1} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
            <img src={ob_ui_2} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
            <img src={ob_ui_3} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
            <img src={ob_ui_4} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
          </div>
          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginRight: -100, ...(isMobile && {flexWrap: 'wrap', marginRight: -32})}}>
            <img src={ob_ui_5} style={{marginRight: 8, width: isMobile ? '90%' : '22%', marginBottom: isMobile ? -140 : 0}} alt="oral brooks" />
            <img src={ob_ui_6} style={{marginRight: 8, width: isMobile ? '90%' : '22%', marginBottom: isMobile ? 48 : 0}} alt="oral brooks" />
            <img src={ob_ui_7} style={{marginRight: 8, width: isMobile ? '90%' : '22%'}} alt="oral brooks" />
            {!isMobile && <img src={ob_ui_8} style={{marginRight: 8, width: isMobile ? '90%' : '22%'}} alt="oral brooks" />}
          </div>

          {!!isMobile && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 40}}>
          <a href={prototype_link} target="_blank" rel="noreferrer" className="circular-button" 
              style={{ width: 144,
                  height: 144,
                  border: '1px solid #000',
                  backgroundColor: '#000',
                  borderRadius: '50%',
                  color: '#fff',
                  textDecoration: 'none',
                  fontSize: 18,
                  fontFamily: 'Roboto',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'}}>
                    View<br/>Prototype
                  </a>
          </div>}
        </div>
      </div>
      
      {!isMobile && <div style={style.stepContainer}>
        <div style={{ display: 'flex', flex: 2 }}> 
            <div style={style.projectStepTitle}>Takeaway</div>
        </div>
        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
          <div style={{ width: '80%' }}>
            <span>The most challenging part of this project was finding a way to connect the three services and finding the right people to interview and test the wireframes with. This project was a lot of fun to design as it really pushed me creatively!</span>
            <span><br/><br/>For next steps, I would play around with the UI design more and test the website again.</span>
            <span><br/><br/>Thank you!</span>
          </div>
        </div>
      </div>}
      <SectionTitle title={isMobile ? 'Next Project' : 'More Projects'} color='#623D2E' />
      <NextProjectSection extra first='instacart' second='naturally' textColor='#623D2E' textColorMobile='#623D2E'/>
      <Footer /> 
    </div>
  );
}

const style = {
  nextProjectSubtitle: {
    color: '#535353', 
    fontSize: 14
  },
  nextProjectTitle: {
    fontFamily: 'Roboto',
    color: '#535353', 
    fontWeight: 700,
    fontSize: 18
  },
  designProcessSubstep: {
    color: '#000000',
    fontSize: 18,
    marginBottom: 16,
  },
  mainBodyText: {
    color: '#000000',
    fontWeight: 400
  },
  stepContainer: {
      display: 'flex',
      margin: "0px 10% 130px 10%",
  },
  subheadings: {
    color: '#193935',
    fontSize: 18
  },
  bold: {
    fontWeight: 600
  },
  lineSection: { 
    display: 'flex', 
    margin: "0px 0px 87px 152px", 
    alignItems: 'center'
  },
  sectionTitle: { 
    fontWeight: 600,
    fontSize: '2.5rem',
    fontFamily: 'Grifo',
    color: '#623D2E'
  },
  projectTitle: {
    fontSize: 64,
    color: '#623D2E',
    fontWeight: 500,
    fontStyle: 'italic',
  },
  projectSubtitle: {
    color: '#193935',
    fontSize: 18,
    fontWeight: 300,
    paddingBottom: 5,
  },
  projectDescription: {
    color: '#193935',
    fontSize: 18,
    fontWeight: 400,
    marginTop: 96,
    width: '70%'
  },
  projectTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    margin: 152,
  },
  projectStepTitle: {
    fontSize: 32,
    color: '#623D2E',
    fontFamily: 'Grifo',
    fontWeight: 700
  },
  designProcessStep: {
    height: 50,
    color: '#193935',
    fontWeight: 600,
    fontSize: 18,
    marginBottom: 16,
    width: 120,
  },
  sectionLine: {
    flex: 1,
    height: 1,
    backgroundColor: '#623D2E',
    marginLeft: 45,
  }
}

const mobileStyle = {
  container: {
    flexDirection: 'column',
    margin: "193px 24px 100px 24px",
    alignItems: 'center',
  },
  titleImageContainer: {
    display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'
  },
  lineSection: { 
    margin: "0px 32px 87px 32px", 
  },
  stepContainer: {
    display: 'flex',
    margin: "0px 32px 87px 32px",
  },
  projectStepTitle: {
    fontSize: 24
  },
  sectionLine: {
    marginRight: -32
  },
  nextProjectSubtitle: {
    color: '#623D2E', 
    fontSize: 14
  },
  nextProjectTitle: {
    color: '#623D2E', 
    fontWeight: 700,
    fontSize: 14,
    marginRight: 4
  },

}

export default OralBrooksProjectPage;
