import React from "react";
import { animated, useSpring } from "react-spring";
import menuCloseIcon from '../../assets/menuCloseIcon.svg'



function FullScreenModal({ visible, setVisible }) {

  const styles = useSpring({ 
      position: 'fixed',
      marginTop: 0,
      zIndex: 999999,
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: '#E8E1D6', 
      opacity: visible ? 1 : 0, 
      transform: visible ? "scale(1)" : "scale(0)" 
    })

  return (
    <div>
        <animated.div style={styles}>
            <div style={{ zIndex: 99999999, position: 'absolute', top: 40, right: 24 }}>
                <div onClick={() => setVisible(false)}>
                    <img style={{ height: 47, width: 47 }} src={menuCloseIcon} alt="atmin mockup" />  
                </div>
            </div>  
            <a href="/#/" style={{ textDecoration: 'none', fontSize: 18, color: '#000', marginBottom: 28}}>HOME</a>
            <a href="/#/about-me" style={{textDecoration: 'none', fontSize: 18, color: '#000', marginBottom: 28}}>ABOUT ME</a>
            <a href="/#/more-projects" style={{textDecoration: 'none', fontSize: 18, color: '#000', marginBottom: 28}}>MORE PROJECTS</a>
            <div style={{height: 55, width: 0, border: '0.5px solid #623D2E', marginBottom: 28}}/>
            <a href="https://linkedin.com/in/yaldasaf" target="_blank" rel="noreferrer" style={{textDecoration: 'none', fontSize: 14, color: '#623D2E', marginBottom: 11}}>LinkedIn</a>
            <a href="mailto:yaldasafarali@hotmail.com" style={{textDecoration: 'none', fontSize: 14, color: '#623D2E', marginBottom: 28}}>Email</a>
        </animated.div>
    </div>
  );
}

export default FullScreenModal;