import useIsMobile from '../../hooks/useIsMobile';

function Footer({ light }) {
  const { isMobile } = useIsMobile();

  return (
      <div style={{...style.container, ...(isMobile && { flexDirection: 'column' })}}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row', marginLeft: '10%', alignItems: 'center', justifyContent: 'center', ...(isMobile && {width: '100%', flex: null, marginLeft: 0})}}>
          <div style={{ ...style.letsConnect, ...(light && style.lightColor), ...(isMobile && { fontSize: 18, marginLeft: 24 }) }}>Let's connect!</div>
          <div style={{ ...style.line, ...(light && style.lightBackground), ...(isMobile && { marginRight: 0 }) }} />
        </div>
        <div style={{ ...style.socials, ...(light && style.lightColor), ...(isMobile && { marginTop: 24, marginRight: 24, width: '100%', justifyContent: 'flex-end'}) }}>
            <a target="_blank" rel="noreferrer" style={{...style.link, ...(light && { color: '#979797' })}} href="https://linkedin.com/in/yaldasaf">Linkedin</a> 
            <div style={{ ...style.dot, ...(light && style.lightBackground) }}/>
            <a target="_blank" rel="noreferrer" style={{...style.link, ...(light && { color: '#979797' })}} href="mailto:yaldasafarali@hotmail.com">Email</a> 
        </div>
      </div>
  );
}

const style = {
    link: {
      textDecoration: 'none',
      color: '#623D2E'
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: 152,
    },
    line: { 
        flex: 1,
        height: 0.5,
        backgroundColor:'#623D2E',
        marginLeft: 45,
        marginRight: 45,
    },

    letsConnect: {
        color: '#623D2E',
        fontSize: 24,
    },
    lightColor: {
      color: '#979797',
    },
    lightBackground: {
      backgroundColor: '#979797',
    },
      
    socials: {
        color: '#623D2E',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '10%',
    },
    dot: {
        backgroundColor: '#623D2E',
        height: 7,
        width: 7,
        borderRadius: 5,
        marginLeft: 40,
        marginRight: 40,
    }
}

export default Footer;
