import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import ND_image from '../../assets/naturally-dvine-sketchbook-image-min.png'
import noesis_image from '../../assets/noesis-sketchbook-image-min.png'
import instacart_image from '../../assets/instacart-sketchbook-image.png'
import AnimatedCursor from '../../components/animated-cursor/animated-cursor'
import oral_brooks_image from '../../assets/oral-brooks-project-image.png'

import './sketchbook.css';
import useIsMobile from '../../hooks/useIsMobile';

function SketchbookPage() {
  const { isMobile } = useIsMobile();

  return (
    <div className="sketchbook">
       <AnimatedCursor
        innerSize={5}
        outerSize={40}
        color='0, 0, 0'
        outerAlpha={0.2}
        innerScale={0.5}
        outerScale={1.5}
      />
      <Header sticky backgroundColor='#F9F6F2' />
      <div style={{ ...styles.mainContainer, ...(isMobile && mobileStyles.mainContainer)}}>
        <div style={{...styles.sketchBookText, ...(isMobile && { position: 'relative', width: '100%' })}}>
          <div style={styles.sketchBookTitle} >Projects</div>
          <span style={{ fontSize: '1.125rem', marginTop: 24}}>A collection of my design work that are a mix of freelance, passion projects, practice work, and more!</span>
        </div>
        <div style={{ flex: 2 }}/>
        <div style={{ display: 'flex', alignItems: isMobile ? 'flex-start' : 'flex-end', flexDirection: 'column', flex: 4 }}>
          <a href="#/oral-brooks" style={{ width: isMobile ? '100%' : '90%', position: 'relative', marginBottom: isMobile ? 0 : 47 }}>
            <img style={{...styles.sketchbookImage, ...(isMobile && mobileStyles.sketchbookImage)}} src={oral_brooks_image} alt="Girl holds a naturally divine product" />
            {!isMobile && <div style={styles.sketchbookTextContainer}>
              <div style={{ fontWeight: 700, fontSize: '1.125rem', color: '#000'  }}>Oral Brooks</div>
              <div style={{ color: '#000'  }}>Freelance</div>
              <div style={{ color: '#000'  }}>Branding asset + website design</div>
            </div>}
          </a>
          {!!isMobile && <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 47, fontSize: 14, width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
            <div style={{ fontWeight: 700, fontSize: '1rem'  }}>{`Oral Brooks`}</div>
            <div>Freelance</div>
            <div>Branding asset + website design</div>
          </div>}
          <a href="#/instacart" style={{ width: isMobile ? '100%' : '90%', position: 'relative', marginBottom: isMobile ? 0 : 47 }}>
            <img style={{...styles.sketchbookImage, ...(isMobile && mobileStyles.sketchbookImage)}} src={instacart_image} alt="Girl holds a naturally divine product" />
            {!isMobile && <div style={styles.sketchbookTextContainer}>
              <div style={{ fontWeight: 700, fontSize: '1.125rem', color: '#000'  }}>Instacart</div>
              <div style={{ color: '#000'  }}>Casestudy</div>
            </div>}
          </a>
          {!!isMobile && <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 47, fontSize: 14, width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
            <div style={{ fontWeight: 700, fontSize: '1rem'  }}>{`Instacart`}</div>
            <div>Casestudy</div>
          </div>}
          <a href="#/naturally" style={{ width: isMobile ? '100%' : '90%', position: 'relative', marginBottom: isMobile ? 0 : 47 }}>
            <img style={{...styles.sketchbookImage, ...(isMobile && mobileStyles.sketchbookImage)}} src={ND_image} alt="mobile mockups" />
            {!isMobile && <div style={styles.sketchbookTextContainer}>
              <div style={{ fontWeight: 700, fontSize: '1.125rem'  }}>Naturally D'vine</div>
              <div>Freelance</div>
              <div>Product Photography</div>
            </div>}
          </a>
          {!!isMobile && <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 47, fontSize: 14, width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
            <div style={{ fontWeight: 700, fontSize: '1rem'  }}>{`Naturally D'vine`}</div>
            <div>Freelance</div>
            <div>Product Photography</div>
          </div>}
          <a href="#/noesis" style={{ width: isMobile ? '100%' : '90%', position: 'relative', marginBottom: isMobile ? 0 : 47 }}>
            <img style={{...styles.sketchbookImage, ...(isMobile && mobileStyles.sketchbookImage)}} src={noesis_image} alt="book branding mockup" />
            {!isMobile && <div style={styles.sketchbookTextContainer}>
              <div style={{ fontWeight: 700, fontSize: '1.125rem', color: '#000'  }}>Noesis</div>
              <div style={{ color: '#000'  }}>Freelance</div>
              <div style={{ color: '#000'  }}>Book Cover Design</div>
            </div>}
          </a>
          {!!isMobile && <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 47, fontSize: 14, width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
            <div style={{ fontWeight: 700, fontSize: '1rem'  }}>{`Noesis`}</div>
            <div>Freelance</div>
            <div>Book Cover Design</div>
          </div>}
        </div>
        {/* <div className="sketchbook-images">
          <div className="sketchbook-image-container">
            <img className="sketchbook-image" src={ND_image} alt="Girl holds a naturally divine product" />
            <div className="sketchbook-text-container">
              <div className="sketchbook-text-company">Naturally D'vine</div>
              <div className="sketchbook-text-description">Product Photography</div>
            </div>
          </div>
          <div className="sketchbook-image-container">
            <img src={off2class_image} alt="Mockups of off2class website" />
            <div className="sketchbook-text-container">
              <div className="sketchbook-text-company">Off2Class</div>
              <div className="sketchbook-text-description">Website</div>
            </div>
          </div>
          <div className="sketchbook-image-container">
            <img src={noesis_image} alt="Mockup of design with Noesis branding on a book" />
            <div className="sketchbook-text-container">
              <div className="sketchbook-text-company">Noesis</div>
              <div className="sketchbook-text-description">Branding</div>
            </div>
          </div>
        </div> */}
      </div> 
      <Footer /> 
    </div>
  );
}

const styles = {
  sketchBookTitle: {
    fontSize: '2.3rem',
    fontFamily: 'Grifo',
    fontWeight: 700,
    color: '#000',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '200px 97px 200px 200px'
  },
  sketchBookText: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 68,
    paddingBottom: 60,
    width: '22%',
    position: 'fixed',
  },
  sketchbookImage: {
    width: '100%',
    height: 'auto',
    borderRadius: 8,
  },
  sketchbookTextContainer: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    color: 'white',
    textAlign: 'right',
  }
}

const mobileStyles = {
  mainContainer: {
    flexDirection: 'column',
    margin: 0,
    paddingTop: 160,
    paddingBottom: 100,
    paddingLeft: 24,
    paddingRight: 24
  },
  sketchbookImage: {
    width: '100%'
  }
}

export default SketchbookPage;
