import { useState, useEffect } from 'react'

function useIsMobile() {
  const [width, setWindowWidth] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => { 
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => 
      window.removeEventListener("resize",updateDimensions);
   }, [])

   const updateDimensions = () => {
     const width = window.innerWidth
     console.log(width);
     if (width < 897) {
       setIsMobile(true);
     } else { 
      setIsMobile(false);
     }
     setWindowWidth(width)
   }

  return { isMobile, width };
}

export default useIsMobile;
