import React, { useState } from 'react';

const TextLink = (props) => {
    const [hover, setHover] = useState(false);

    return (
        <a 
            href={props.link} 
            onMouseEnter={() => {
                setHover(true)
            }}
            onMouseLeave={() => {
                setHover(false)
            }}
            style={{ 
                transition: 'all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1)',
                display: 'flex', color: 'black', ...(hover && { fontWeight: 'bold' }), flexDirection: 'column', textDecoration: 'none'}}>
              {props.children}
            <div style={{ height: 3, width: '100%', backgroundColor: '#E0BE74', ...(hover && { backgroundColor: "#623D2E" })}}/>
        </a>
    )
}

export default TextLink;