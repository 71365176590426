import { useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import logo from '../../assets/ys_dark_logo.png';
import useIsMobile from '../../hooks/useIsMobile';
import menuIcon from '../../assets/menuIcon.svg'
import menuIconDark from '../../assets/menuIconDark.svg'
import { useLocation } from 'react-router-dom'

import './header.css';
import FullScreenModal from '../center-modal/center-modal';
import AnimatedCursor from '../animated-cursor/animated-cursor';


function Header({ light, sticky, backgroundColor }) {
  const location = useLocation();
  console.log(location.pathname)
  const { isMobile } = useIsMobile();
  const [menuVisible, setMenuVisible] = useState(false);

  menuVisible ? disableBodyScroll(document) : enableBodyScroll(document)

  return (
    <div style={{ position: 'relative' }}>
      <FullScreenModal visible={menuVisible} setVisible={(v) => setMenuVisible(v)} />
      <header style={{...style.appHeader, ...(isMobile && mobileStyle.appHeader), ...(sticky && style.sticky), backgroundColor: backgroundColor}}>
          <a href='/#' style={{ marginLeft: 97, ...(isMobile && { marginLeft: 24 }) }}>
            <img src={logo} className="app-logo" alt="logo" />
          </a>
          <div style={{ flex: 1}} />
          {!isMobile && <div  style={{ marginRight: 97, ...(isMobile && { marginRight: 24 }) }} className="menu">
            <a href="#/about-me" style={{ fontWeight: location.pathname === '/about-me' ? 700 : 400 }} className={`menu-item ${light ? 'light' : ''}`}>About me</a>
            <a href="#/more-projects" style={{ fontWeight: location.pathname === '/more-projects' ? 700 : 400 }} className={`menu-item last ${light ? 'light' : ''}`}>More projects</a>
          </div>}
          {!!isMobile && 
            <div onClick={() => setMenuVisible(!menuVisible)}>
              <img style={{ height: 47, width: 47, marginRight: 24 }} src={light ? menuIcon : menuIconDark} alt="atmin mockup" />  
            </div>
          }
      </header>
        {menuVisible && <AnimatedCursor
            innerSize={5}
            outerSize={40}
            color='0, 0, 0'
            outerAlpha={0.2}
            innerScale={0.5}
            outerScale={1.5}
        />}
    </div>
    
  );
}

const height = 130;

const style = {
  appHeader: {
    height: height,
    marginBottom: -1 * height,
    display: 'flex',
    // paddingLeft: 97,
    // paddingRight: 97,
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 999,
  },
  sticky: { 
    position: 'fixed',
    top: 0,
    width: '100vw'
  }
}

const mobileStyle = {
  appHeader: {
    height: 100,
  }
}

export default Header;
