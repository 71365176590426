import { animated, useSpring } from '@react-spring/web'

const FadeIn = ({ timeout, children, right, left}) => {
  const r = {
    start: {x: 100},
    end: {x: 0}
  }
  const l = {
    start: {x: -100},
    end: {x: 0}
  }

  const styles = useSpring({
    from: { opacity: 0, y: right || left ? null : 100, ...(right && r.start), ...(left && l.start)},
    to: { opacity: 1, y: right || left ? null : 0, ...(right && r.end), ...(left && l.end)},
    delay: timeout,
  })


  return (<div style={{ position: 'relative', overflow: 'hidden'}}>
        <animated.div style={styles}>
            {children}
        </animated.div>
    </div>
  )
}

export default FadeIn;