import * as React from "react";
import { Routes, Route } from "react-router-dom";
import Home from './Home';
import AboutMe from './pages/about-me/AboutMe'
import Sketchbook from './pages/sketchbook/Sketchbook'
import AtminProjectPage from './pages/projects/atmin'
import OralBrooksProjectPage from './pages/projects/oral-brooks'
import InstacartProjectPage from './pages/projects/instacart'
import NaturallyProjectPage from './pages/projects/naturally'
import NoesisProjectPage from './pages/projects/noesis'
import GuestAreaPage from './pages/guest-area/GuestArea'
import O2CProjectPage from './pages/projects/off2class'
import MenuTest from './components/header/fullMenu'
// import ProtectedRoute from "./components/protected-route/ProtectedRoute";

function App() {
  return (
    <div>
       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="about-me" element={<AboutMe />} />
          <Route path="guest-area" element={<GuestAreaPage />} />
          <Route path="more-projects" element={<Sketchbook />} />
          <Route path="atmin" element={<AtminProjectPage />} />
          <Route path="oral-brooks" element={<OralBrooksProjectPage />} />
          <Route path="instacart" element={<InstacartProjectPage />} />
          {/* <Route
            path="instacart"
            element={
              <ProtectedRoute correctPassword="secret123">
                <InstacartProjectPage />
              </ProtectedRoute>
            }
          /> */}
          <Route path="naturally" element={<NaturallyProjectPage />} />
          <Route path="noesis" element={<NoesisProjectPage />} />
          <Route path="off2class" element={<O2CProjectPage />} />
          <Route path="menu-test" element={<MenuTest />} />

      </Routes>
    </div>
     
  );
}

export default App;