import React from 'react'

import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import instacart_project_image from '../../assets/instacart-project-image.png';

import 'react-medium-image-zoom/dist/styles.css'

import ob_persona_1 from '../../assets/instacart-persona-1.jpg';
import ob_persona_2 from '../../assets/instacart-persona-2.jpg';

import instacart_sketch from '../../assets/instacart-sketch-light.png';

import instacart_wireframe_1 from '../../assets/instacart-wireframes-1.png';
import instacart_wireframe_2 from '../../assets/instacart-wireframes-2.png';
import instacart_wireframe_3 from '../../assets/instacart-wireframes-3.png';
import instacart_wireframe_4 from '../../assets/instacart-wireframes-4.png';
import instacart_wireframe_5 from '../../assets/instacart-wireframes-5.png';
import instacart_wireframe_6 from '../../assets/instacart-wireframes-6.png';
import instacart_wireframe_7 from '../../assets/instacart-wireframes-7.png';
import instacart_wireframe_8 from '../../assets/instacart-wireframes-8.png';


import instacart_video_1 from '../../assets/instacart_video_1.mp4';
import instacart_video_2 from '../../assets/instacart_video_2.mp4';

import instacart_ui_1 from '../../assets/instacart-ui-1.png';
import instacart_ui_2 from '../../assets/instacart-ui-2.png';
import instacart_ui_3 from '../../assets/instacart-ui-3.png';
import instacart_ui_4 from '../../assets/instacart-ui-4.png';
import instacart_ui_5 from '../../assets/instacart-ui-5.png';
import instacart_ui_6 from '../../assets/instacart-ui-6.png';
import instacart_ui_7 from '../../assets/instacart-ui-7.png';
import instacart_ui_8 from '../../assets/instacart-ui-8.png';
import instacart_ui_9 from '../../assets/instacart-ui-9.png';
import instacart_ui_10 from '../../assets/instacart-ui-10.png';


// import ob_image from '../../assets/ob_home_image_alternate.png';
// import atmin_image from '../../assets/atmin-next-project.png';

import './projects.css';
import useIsMobile from '../../hooks/useIsMobile';
// import { AnimatedBg, Transition } from 'scroll-background';
import AnimatedCursor from '../../components/animated-cursor/animated-cursor'
import { NextProjectSection, ProjectTitleSection, SectionTitle, SubsectionTitle } from '../../components/shared-proj-components';


const prototype_link = "https://www.figma.com/proto/jP4Q524Xjijm0ZpTIHh0Qp/Instacart-Casestudy?page-id=0%3A1&node-id=21%3A71&viewport=241%2C48%2C0.79&scaling=min-zoom&starting-point-node-id=21%3A71";

function InstacartProjectPage() {
  const { isMobile, width } = useIsMobile();
  // const [background, setBackground] = useState('rgb(246, 247, 249)');

  return (
    <div style={{ backgroundColor: '#E8E1D6' }} className="app">
      <AnimatedCursor
        innerSize={5}
        outerSize={40}
        color='0, 0, 0'
        outerAlpha={0.2}
        innerScale={0.5}
        outerScale={1.5}
      />
      <Header/>
      <ProjectTitleSection
        color={'#4DAB33'}
        subtitleColor={'#193935'}
        title={"Instacart"} 
        subtitle={'CASESTUDY'} 
        date={'JAN 2022'} 
        description={'A casestudy on Instacarts user experience and providing solutions for their recipe page.'} 
        image={instacart_project_image}
        descriptionWidth={410}
      />
      <SectionTitle title='Design Process' color='#623D2E' />
      <div style={{...style.stepContainer, ...(isMobile && mobileStyle.stepContainer)}}>
        {!isMobile && <div style={{ display: 'flex', flex: 2 }}>
          <a href={prototype_link} target="_blank" rel="noreferrer" className="circular-button" 
            style={{ width: 144,
                height: 144,
                border: '1px solid #000000',
                backgroundColor: '#000000',
                borderRadius: '50%',
                color: '#fff',
                textDecoration: 'none',
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center'}}>
            View< br />Prototype
          </a>
        </div>}
        
        <div style={{ display: 'flex', flexWrap: 'wrap', flex: 4}}>
          <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, flexDirection: 'row', ...(isMobile && {justifyContent: 'center'}) }}>
            {/* <div style={{ color: '#193935' }} className="design-process-step design-process-number">1.</div> */}
            <div>
              <div style={{...style.designProcessStep, ...(isMobile && { fontSize: 14 })}}>Day 1</div>
              <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>Problem Space</div>
            </div>
          </div>
          <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, flexDirection: 'row' }}>
            {/* <div className="design-process-step design-process-number">2.</div> */}
            <div>
              <div style={{...style.designProcessStep, ...(isMobile && { fontSize: 14 })}}>Day 2</div>
              <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>Interview Users</div>
              <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>Personas</div>
            </div>
          </div>
          <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, flexDirection: 'row', ...(isMobile && {justifyContent: 'center'}) }}>
            {/* <div className="design-process-step design-process-number">3.</div> */}
            <div>
              <div style={{...style.designProcessStep, ...(isMobile && { fontSize: 14 })}}>Day 3</div>
              <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>Sketch</div>
              <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>Wireframes</div>
            </div>
          </div>
          <div style={{ display: 'flex', flex: `1 1 ${isMobile ? '50%' : '25%'}`, flexDirection: 'row' }}>
            {/* <div className="design-process-step design-process-number">4.</div> */}
            <div>
              <div style={{...style.designProcessStep, ...(isMobile && { fontSize: 14 })}}>Day 4</div>
              <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>UI Design</div>
              <div style={{...style.designProcessSubstep, ...(isMobile && { fontSize: 14 })}}>Prototype</div>
            </div>
          </div>
          {!!isMobile && <div style={{ width: '100%', marginTop: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 40}}>
          <a href={prototype_link} target="_blank" rel="noreferrer"  className="circular-button" 
              style={{ width: 144,
                    height: 144,
                    border: '1px solid #000',
                    backgroundColor: '#000',
                    borderRadius: '50%',
                    color: '#fff',
                    textDecoration: 'none',
                    fontSize: 18,
                    fontFamily: 'Roboto',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'}}>
                      View<br/>Prototype
                    </a>
            </div>}
        </div>
      </div>
      
      <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
        <SubsectionTitle title='Day 1' color='#623D2E' lineColor='#623D2E' />        

        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
          <span>Many people buy ingredients based on the recipes they plan to make and or from their weekly essential items they often buy. By designing a recipe page that is curated for users, Instacart can meet both those needs. <br/><br/></span>
          <span>The problem I am trying to solve is the lack of excitement users face with the current recipe page due to the limited recipe options and the lack of personalization from the app. These problems can be solved by desining a recipe page that:<br/><br/></span>
          <ul>
                <li style={{ marginBottom: 8 }}>Considers the users diet style and restrictions to showcase personalized recipe options</li>
                <li style={{ marginBottom: 8 }}>Allows users to edit and review recipes, add their own, and share it with others as a way to facilitate a community </li>
          </ul>
          <br/>
        </div>
      </div>
     
      <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
        <SubsectionTitle title='Day 2' color='#623D2E' lineColor='#623D2E' />        

        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
         {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 8 }}>User Interviews</div>}
          <span>The 4 users I interviewed were between the ages of 23-40 with a busy schedule that find  food delivery apps very useful in their routine. My goal for these interviews were to: <br/></span>
 
          <ul style={{ marginBottom: 48 }}>
                <li style={{ marginBottom: 8 }}>Identify their grocery shopping habits and practices </li>
                <li style={{ marginBottom: 8 }}>Understand their goals and needs when it comes to planning for a future grocery trip</li>
                <li style={{ marginBottom: 8 }}>Understand pain points or reservations with grocery shopping in person and using grocery delivery apps, like Instacart</li>          
          </ul>


          <span>Here are just some of the questions I asked during the interview and the personas that resulted from it: </span>
          <ul style={{ marginBottom: 48 }}>
                <li style={{ marginBottom: 8 }}>Tell me about a time where you used an app to have your groceries delivered to you.</li>
                <li style={{ marginBottom: 8 }}>Describe your experience using a delivery app like instacart/ grubhub/hellofresh.</li>
                <li style={{ marginBottom: 8 }}>What do you wish was different about your shopping experience while using a delivery app?</li>
          </ul>
          {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 40 }}>Personas</div>}
          <img src={ob_persona_1} style={{width: '100%', marginBottom: 40, border: '1px solid #F88816'}} alt="persona 1 George" />
          <img src={ob_persona_2} style={{width: '100%', border: '1px solid #F88816'}} alt="persona 2 Aleema" />
        </div>
      </div>
      <div id="anchor" style={{ paddingTop: 40 }}>
        <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
          <SubsectionTitle title='Day 3' color='#623D2E' lineColor='#623D2E' />        

          <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
            {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 8 }}>Sketches</div>}
            <span>After interviewing my users and understanding their pain points with using apps like Instacart, I decided to sketch a detailed recipe page that provides a lot more functions for users to engage with. </span>
            
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 40, ...(!isMobile && {marginRight: -100}) }}>
              <div style={{ flex: 1, marginRight: 24}}>
                <img src={instacart_sketch} style={{width: '100%'}} alt="oral brooks" />
              </div>
            </div>
            {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 12, marginTop: 40 }}>Wireframes</div>}
            <div style={{ marginBottom: 12, display: 'flex', flex: 1, flexDirection: 'row', marginRight: -100, ...(isMobile && {flexWrap: 'wrap', marginRight: -32 })}}>
              <img src={instacart_wireframe_1} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
              <img src={instacart_wireframe_2} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
              <img src={instacart_wireframe_3} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
              <img src={instacart_wireframe_4} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
            </div>
            <div style={{ marginBottom: 52, display: 'flex', flex: 1, flexDirection: 'row', marginRight: -100, ...(isMobile && {flexWrap: 'wrap', marginRight: -32})}}>
              <img src={instacart_wireframe_5} style={{marginRight: 8, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
              <img src={instacart_wireframe_6} style={{marginRight: 8, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
              <img src={instacart_wireframe_7} style={{marginRight: 8, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
              <img src={instacart_wireframe_8} style={{marginRight: 8, width: isMobile ? '45%' : '22%'}} alt="oral brooks" />
            </div>
          </div>
        </div>

      
        <div style={{...style.stepContainer, ...(isMobile && {...mobileStyle.stepContainer, flexDirection: 'column'})}}>
          <div style={{ display: 'flex', flex: width < 1400 ? 1 : 2, ...( isMobile ? {alignItems: 'center', marginBottom: 40 } : { flexDirection: 'column', justifyContent: 'space-between'}) }}> 
          <div style={{...style.projectStepTitle, ...(isMobile && mobileStyle.projectStepTitle)}}>Day 4</div>
              {!!isMobile && <div style={{...style.sectionLine, ...(isMobile && { ...mobileStyle.sectionLine, backgroundColor: '#E0BE74'})}} />}
                {!(width < 1400) &&
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: 600}}>
                    <a href={prototype_link} target="_blank" rel="noreferrer" className="circular-button" 
                        style={{ width: 144,
                        height: 144,
                        border: '1px solid #000',
                        backgroundColor: '#000',
                        borderRadius: '50%',
                        color: '#fff',
                        textDecoration: 'none',
                        fontFamily: 'Roboto',
                        fontSize: 18,
                        textAlign: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'}}>
                          View<br/>Prototype
                    </a>
                  </div>}
                <div />
          </div>
              
          <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>            
            {!isMobile && <div style={{ ...style.subheadings, ...style.bold, marginBottom: 24 }}>UI Design</div>}
            <span>After wireframing, I went to prototyping the recipe page. Here are the main differences: </span>
            <ul>
              <li>Users will have access to their favourites, previously ordered, and their cookbook all in one page. They will also be able to add their own recipe underneath the cookbook tab for users to share personal recipes with their friends and family.</li>
              <li style={{ listStyleType: 'none' }}>
                {/* <img src={instacart_ui_1} style={{marginRight: 8, marginBottom: 24, marginTop: 24, width: isMobile ? '100%' : '30%'}} alt="oral brooks" /> */}
                  {/* <Zoom style={{ width: '100%' }}> */}
                    <video style={{marginRight: 8, marginBottom: 24, marginTop: 24, width: isMobile ? '100%' : '40%'}} className='VideoTag' autoPlay playsInline loop muted>
                          <source src={instacart_video_2} type='video/mp4'/>
                    </video>  
                  {/* </Zoom>   */}
              </li>
              <li>
                In the recipe, users will be able to switch between ingredients and instructions as a way to reduce scroll space, which also makes room for a new review section at the bottom. The recipe can also be edited and, once edited, the copy will move to their cookbook tab. 
              </li>
              <li style={{ listStyleType: 'none' }}>
                {/* <img src={instacart_ui_1} style={{marginRight: 8, marginBottom: 24, marginTop: 24, width: isMobile ? '100%' : '30%'}} alt="oral brooks" /> */}
                  <video  style={{marginRight: 8, marginBottom: 24, marginTop: 24, width: isMobile ? '100%' : '40%'}} className='VideoTag' autoPlay playsInline loop muted>
                        <source src={instacart_video_1} type='video/mp4'/>
                  </video>   
              </li>
            </ul>

            {width > 1400 && <span>Click the button on the left to experience how the app functions.</span>}
            {!isMobile && <div style={{ marginBottom: 12, marginTop: 32, display: 'flex', flex: 1, flexDirection: 'row', marginRight: -100, ...(isMobile && {flexWrap: 'wrap', marginRight: -32 })}}>
              <img src={instacart_ui_1} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '18%'}} alt="oral brooks" />
              <img src={instacart_ui_2} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '18%'}} alt="oral brooks" />
              <img src={instacart_ui_3} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '18%'}} alt="oral brooks" />
              <img src={instacart_ui_4} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '18%'}} alt="oral brooks" />
              <img src={instacart_ui_5} style={{marginRight: 8, marginBottom: 24, width: isMobile ? '45%' : '18%'}} alt="oral brooks" />
            </div>}
            {!isMobile && <div style={{ marginBottom: 52, display: 'flex', flex: 1, flexDirection: 'row', marginRight: -100, ...(isMobile && {flexWrap: 'wrap', marginRight: -32})}}>
              <img src={instacart_ui_6} style={{marginRight: 8, width: isMobile ? '45%' : '18%'}} alt="oral brooks" />
              <img src={instacart_ui_7} style={{marginRight: 8, width: isMobile ? '45%' : '18%'}} alt="oral brooks" />
              <img src={instacart_ui_8} style={{marginRight: 8, width: isMobile ? '45%' : '18%'}} alt="oral brooks" />
              <img src={instacart_ui_9} style={{marginRight: 8, width: isMobile ? '45%' : '18%'}} alt="oral brooks" />
              <img src={instacart_ui_10} style={{marginRight: 8, width: isMobile ? '45%' : '18%'}} alt="oral brooks" />
            </div>}
            {!!isMobile && <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 40}}>
            <a href={prototype_link} target="_blank" rel="noreferrer" className="circular-button" 
              style={{ width: 144,
                    height: 144,
                    border: '1px solid #000',
                    backgroundColor: '#000',
                    borderRadius: '50%',
                    color: '#fff',
                    textDecoration: 'none',
                    fontSize: 18,
                    fontFamily: 'Roboto',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'}}>
                      View< br/>Prototype
                    </a>
            </div>}
          </div>
        </div>
        
        {!isMobile && <div style={style.stepContainer}>
          <div style={{ display: 'flex', flex: 2 }}> 
              <div style={{...style.projectStepTitle}}>Takeaway</div>
          </div>
          <div style={{ display: 'flex', flex: 4, flexDirection: 'column', ...style.mainBodyText}}>
            <div style={{ width: '80%' }}>
              <span>It was a great experience to learn about people’s habits when it comes to their grocery shopping practices as everyone has a unique story to share. It was challenging to do this project in such a short amount of time, but it allowed me to keep my focus on the problem at hand.</span>
              <span><br/><br/>For next steps, I would test the prototype before continuining forward.</span>
              <span><br/><br/>Thank you!</span>
            </div>
          </div>
        </div>}
        <SectionTitle title={isMobile ? 'Next Project' : 'More Projects'} color='#623D2E' />
        <NextProjectSection extra first='oral-brooks' second='noesis' textColor='#535353' textColorMobile='#623D2E' />
        <Footer /> 
      </div>
    </div>
  );
}

const style = {
  nextProjectSubtitle: {
    color: '#535353', 
    fontSize: 14
  },
  nextProjectTitle: {
    fontFamily: 'Roboto',
    color: '#535353', 
    fontWeight: 700,
    fontSize: 18
  },
  designProcessSubstep: {
    color: '#000000',
    fontSize: 18,
    marginBottom: 16,
  },
  mainBodyText: {
    color: '#000000',
    fontWeight: 400
  },
  stepContainer: {
      display: 'flex',
      margin: "0px 10% 100px 10%",
  },
  subheadings: {
    color: '#202020',
    fontSize: 18
  },
  bold: {
    fontWeight: 600
  },
  lineSection: { 
    display: 'flex', 
    margin: "0px 0px 87px 152px", 
    alignItems: 'center'
  },
  sectionTitle: { 
    fontWeight: 600,
    fontSize: 36,
    color: '#623D2E'
  },
  projectTitle: {
    fontSize: 64,
    color: '#4DAB33',
    fontWeight: 500,
    fontStyle: 'italic',
  },
  projectSubtitle: {
    color: '#193935',
    fontSize: 18,
    fontWeight: 300,
    paddingBottom: 5,
  },
  projectDescription: {
    color: '#193935',
    fontSize: 18,
    fontWeight: 400,
    marginTop: 96,
    width: '70%'
  },
  projectTitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    margin: "250px 152px 100px 152px",
    justifyContent: 'center',
  },
  projectStepTitle: {
    fontSize: 32,
    color: '#623D2E',
    fontWeight: 700,
    fontFamily: 'Grifo',
  },
  designProcessStep: {
    height: 50,
    color: '#202020',
    fontSize: 18,
    marginBottom: 16,
    fontWeight: 600,
    width: 120,
  },
  sectionLine: {
    flex: 1,
    height: 1,
    backgroundColor: '#623D2E',
    marginLeft: 45,
  }
}

const mobileStyle = {
  container: {
    flexDirection: 'column',
    margin: "193px 24px 100px 24px",
    alignItems: 'center',
  },
  titleImageContainer: {
    display: 'flex', width: '50%', justifyContent: 'center', alignItems: 'center'
  },
  lineSection: { 
    margin: "0px 32px 87px 32px", 
  },
  stepContainer: {
    display: 'flex',
    margin: "0px 32px 87px 32px",
  },
  projectStepTitle: {
    fontSize: 24
  },
  sectionLine: {
    marginRight: -32
  },
  nextProjectSubtitle: {
    color: '#535353', 
    fontSize: 14
  },
  nextProjectTitle: {
    color: '#535353', 
    fontWeight: 700,
    fontSize: 14,
    marginRight: 4
  },
}

export default InstacartProjectPage;
